<template>
  <div v-loading.lock="projectStore.loaded" class="elv-entity-settings-container">
    <DetailHeader class="elv-project-setting-info" type="project" />
    <el-scrollbar class="elv-entity-settings-content-add-entity-info">
      <p class="elv-entity-settings-content-add-entity-info-title">{{ t('report.projectReadyForUse') }}</p>
      <p class="elv-entity-settings-content-add-entity-info-desc">{{ t('report.upEntityInformation') }}</p>
      <EntitySettingForm :fromType="'entity'" />
    </el-scrollbar>
  </div>
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash-es'
import { isMobile } from '@/lib/utils'
import { ElMessage } from 'element-plus'
import { ProjectPermissionLevel } from '#/ProjectTypes'
import DetailHeader from '../components/DetailHeader.vue'
import { useProjectStore } from '@/stores/modules/project'
import { useMenuGlobalStore } from '@/stores/modules/global/menu'
import EntitySettingForm from '@/components/Project/EntitySettingForm.vue'

const { t } = useI18n()
const route = useRoute()
const projectStore = useProjectStore()
const menuGlobalStore = useMenuGlobalStore()

const currentProjectId = ref('')

const headerWidth = computed(() => {
  return isMobile() || !menuGlobalStore.isFixedMenu ? 'calc(100vw - 48px)' : 'calc(100vw - 179px)'
})

const getProjectDetail = async () => {
  if (!isEmpty(projectStore.projectDetail)) return
  const urlParams = new URLSearchParams(window.location.search)
  const projectIdInfo = urlParams?.get('projectId')
  const projectId: string =
    (route.params?.projectId as string) || (route.query?.projectId as string) || projectIdInfo || ''
  if (!projectId) return
  currentProjectId.value = projectId
  try {
    await projectStore.fetchProjectDetail(projectId)
  } catch (error: any) {
    console.log(error?.message)
  }
}

const showSubscriptionToast = () => {
  const isEnableSubscription =
    projectStore.projectDetail?.plan === ProjectPermissionLevel.BASIC ||
    projectStore.projectDetail?.plan === ProjectPermissionLevel.STANDARD
  if (!isEnableSubscription) return
  const currentProjectToastSession = sessionStorage.getItem(`${currentProjectId.value}-subscription-toast`)
  const urlParams = new URLSearchParams(window.location.search)
  const fromTab = urlParams?.get('fromTab')
  if (fromTab && fromTab === 'payment' && !currentProjectToastSession) {
    ElMessage.success(t('message.subscriptionSuccessTip'))
    sessionStorage.setItem(`${currentProjectId.value}-subscription-toast`, 'true')
  }
}

onMounted(async () => {
  await getProjectDetail()
  showSubscriptionToast()
})
</script>

<style lang="scss" scoped>
.elv-entity-settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: v-bind('headerWidth');
  height: 100%;
  position: relative;

  .elv-project-setting-info {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #dde1e6;
  }

  .elv-entity-settings-content-add-entity-info {
    flex: 1;
    width: 100%;
    min-height: 0;
    box-sizing: border-box;
    padding-top: 32px;

    :deep(.el-scrollbar__wrap) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .el-scrollbar__view {
        max-width: 572px;
      }
    }

    &-title {
      font-size: 24px;
      font-weight: 700;
      color: #4e515a;
      margin-bottom: 20px;
    }

    &-desc {
      font-size: 14px;
      font-weight: 600;
      color: #636b75;
    }

    .elv-apply-project-form {
      margin-top: 32px;
    }
  }
}
</style>

<style>
.elv-layout-main {
  padding: 0 !important;
}
</style>
